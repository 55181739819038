section{
  margin:0 auto;
  width:80%;
  display: flex;
  flex-wrap: wrap;
}
.empty-message{
  text-align: center;
  width: 100%;
  font-style: italic;
  color:rgb(175, 172, 172);
  font-size:.6rem;
  text-transform: uppercase;
  letter-spacing: 2px;
  margin-top: 10vh;
}
section .imageCard{
  width: 23%;
  margin:0 1%;
}
.imageCard p{
  font-size:.7rem;
  color:rgb(175, 172, 172);
  padding:.5rem 0;
  margin:0;
}
.imageCard img {
  max-width:100%;
}

@media all and (max-width:900px) {
  section{
    width: 96%;
  }
  section .imageCard{
    width: 48%;
    margin:0 1%;
  }
}
@media all and (max-width:500px) {
  section .imageCard{
    width: 98%;
    margin:0 1%;
  }
}


