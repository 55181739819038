.modal{
  position: absolute;
  height:100vh;
  width: 100%;
  background-color: rgba(255,255,255,.9);
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
  justify-items:center;
}
.container{
  position: relative;
  width: 90%;
}
.modal p{
  margin: 0;
  padding-bottom:1rem;
  text-transform: uppercase;
  letter-spacing: -2px;
  font-size: .8rem;
  color:rgb(175, 172, 172);
  display: inline-block;
}
.modal button {
  position: absolute;
  right:0;
  top:5px;
  background-image: url('../public/x.png');
  background-color: transparent;
  filter: opacity(0.3);
  border:none;
  height: .8rem;
  width: .8rem;
  background-size:cover;
}

.modal img{
  width: 100%;
  border:1px solid black;
}

/* landscape-ish */
@media (min-aspect-ratio: 22/17) {
  .modal .container{
    width:calc(22/17 * 80vh);
  }
}