header{
  text-transform: uppercase;
  text-align: center;
  margin: 0;
}

h1{
  display: flex;
  justify-content:space-between;
  margin: 0;
}

.letter{
  flex-grow:1;
}

@media all and (max-width:600px) {
  header{
    letter-spacing: 1rem;
  }
  h1{
    margin: 0;
  }
}
